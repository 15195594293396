import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { PdfViewer } from '../pdfViewer';

const styles = () => ({
	table: {
		borderCollapse: 'collapse',
		margin: '0 auto',
		textAlign: 'center',
		overflow: 'auto'
	}
});

const QuotationPlans = (props) => {

	return (
		<div className="max-w-5xl mx-auto w-full text-justify">
			<PdfViewer url={props.url} />
		</div>
	);
};

QuotationPlans.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withMobileDialog({ breakpoint: 'xs' })(
	withStyles(styles)(QuotationPlans)
);
