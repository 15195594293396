import React, { Component } from 'react';
import Router from 'next/router';
import jwt_decode from 'jwt-decode';

import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { bindActionCreators } from 'redux';

import TagManager from 'react-gtm-module';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { SelectSimpleOutlined, DateInput, TextInput } from 'components/commons';
import { SnackBar, SpinnerV, NotFoundCity } from 'components/widgets';
import CityList from 'components/utils/CityList';
import TablePlans from 'components/plans/TablePlans';
import QuotationBeneficiaries from 'components/plans/QuotationBeneficiaries';
import BaseLead from 'models/BaseLead';
import ReactTelInput from 'react-telephone-input';
import Flags from '../../assets/images/flags.png';
import Validations from 'components/utils/Validations';
import { PrivacyPolicyDialog } from 'components/terms';
import BaseTitular from 'components/utils/BaseTitular';

class FastQuotation extends Component {
	state = {
		step: 1,
		lead: BaseLead,
		structure: {
			country: this.props.quotation?.country,
			city: this.props.quotation?.city,
			product_id: '',
			product_type: {},
			products: [],
			questions: [],
			birth_date: null,
			price: 0
		},
		products: [],
		options: [],
		special_services: [],
		payment_methods: [],
		openAlert: false,
		messageAlert: '',
		loading: false,
		dialog: false,
		countryCode: '',
		leadDialog: false,
		selectedCountryCode: 'do',
		isMobile: false,
	};
	_isMounted = false;

	async componentDidMount() {
		this._isMounted = true;

		const isMobile = Router?.router?.asPath?.includes('/m');
		this.setState({ isMobile });

		const response = await this.props.actions.getLocation();
		this.setState({
			countryCode: (response?.countryCode || 'do').toLowerCase()
		});
		if (this.props.token) {
			if (!this.props.titular.email) {
				if (this._isMounted) await this.getData();
			} else {
				// get data from token
				const { data } = jwt_decode(this.props.token || this.props.mobileToken);

				const { first_name, last_name, phone_one, email } = this.props.titular;
				if(this._isMounted)
					this.setState({ lead: {
						...this.state.lead,
						// for some reason it did not allow the previous object to be set by default
						first_name: first_name || data?.first_name,
						last_name: last_name || data?.last_name,
						phone_one: data?.phone_one ? data?.phone_one : phone_one,
						email: email || data?.email
					}});
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	/*Get titular Data. (Case:titular logged and quote)*/
	getData = async () => {
		this.setState({ loading: true });
		let data = await this.props.actions.getTitularData();
		if (data.success === true) {
			const titular = data.titular;
			let { titular_email } = this.props;
			let saveTitular = { ...BaseTitular, ...titular };
			const { first_name, last_name, phone_one } = saveTitular;
			this.setState({
				lead: {
					...this.state.lead,
					first_name,
					last_name,
					phone_one,
					email: titular_email
				}
			});
			this.setState({ loading: false });
			this.props.actions.handleStateAffiliation(
				'payment_info',
				data.payment_info
			);
			if (saveTitular.first_name) {
				saveTitular.formErrors = [];
				saveTitular.email = titular_email;
				if (saveTitular && saveTitular.share_link) {
					await this.props.actions.SaveSharedLink({
						share_link: saveTitular.share_link,
						share_link_discount: saveTitular.share_link_discount
					});
				}
				if (saveTitular.doc_dni) saveTitular.document_dni = 'Archivo cargado';
				this.props.actions.handleStateAffiliation('titular', {
					...BaseTitular,
					...saveTitular
				});
			} else {
				saveTitular.formErrors = [];
				saveTitular.email = titular_email;
				this.props.actions.handleStateAffiliation('titular', {
					...BaseTitular,
					...saveTitular
				});
			}
			if (
				saveTitular.include_titular === true &&
				!saveTitular.questions &&
				saveTitular.product_type
			) {
				saveTitular.questions = saveTitular.product_type.questions;
				this.props.actions.handleStateAffiliation('titular', {
					...BaseTitular,
					...saveTitular
				});
			}
		} else {
			this.setState({ loading: false });
			this.setState({ openAlert: data.openAlert });
			this.setState({ messageAlert: data.openAlert });
		}
	};

	handleInput = (event, step = 1) => {
		const { name, value } = event.target;
		let { quotation } = this.props;
		let { lead } = this.state;
		quotation[name] = value;
		lead.city_interest = quotation.city;
		this.setState({ lead });
		if (name === 'amount') {
			let { parients } = quotation;
			let baseParients = parients;
			for (const parient in parients)
				if (parients[parient]['birth_date'] === null)
					baseParients.splice(parient, 1);
			for (
				let index = parients.length + 1;
				index <= quotation.amount;
				index++
			) {
				quotation.parients.push({ ...this.state.structure });
			}
			if (parients.length > quotation.amount) {
				let difference = parients.length - quotation.amount;
				for (let index = 0; index < difference; index++)
					quotation.parients.pop();
			}
		}
		this.props.actions.saveQuotation(quotation);
		this.setState({ step });
	};

	handleInputLead = (event, telNumber) => {
		let lead = this.state.lead;
		if (event === 'phone_one') {
			lead.phone_one = telNumber;
			Validations.validateFieldHeadLineRequired(
				lead,
				this.saveState,
				'phone_one',
				telNumber
			);
			if (lead.phone_one === '+' || lead.phone_one === '+1')
				delete lead.formErrors.phone_one;
		} else {
			let { name, value } = event.target;
			lead[name] = value;
			Validations.validateFieldHeadLineRequired(
				lead,
				this.saveState,
				name,
				value
			);
		}
		this.setState({ lead });
	};

	handleDate = (date, index, name, step = 1) => {
		let { quotation } = this.props;
		quotation.parients[index][name] = date;
		this.props.actions.saveQuotation(quotation);
		this.setState({ step });
	};

	handleGetProducts = async (country = 'República Dominicana') => {
		this.setState({ loading: true });
		const result = await this.props.actions.getProducts(country);
		this.setState({ ...result, loading: false });
	};

	handleChangeStep = (step) => {
		const { quotation, fromHome } = this.props;
		if (step === 2 && fromHome === true) {
			const event_quotation = {
				dataLayer: {
					event: 'evento-cotizacion-home-uno'
				}
			};
			TagManager.dataLayer(event_quotation);
		}
		this.props.actions.handleStateAffiliation('quotation', quotation?.parients);
		this.handleGetProducts(quotation?.country);
		this.handleSubmit();
		this.setState({ step });
	};

	handleSubmit = async () => {
		let { lead } = this.state;
		let { titular } = this.props;
		lead.marketing_campaign = this.props.marketing_campaign;
		lead.origin = this.props.origin;
		lead.share_link = this.props.share_link;
		lead.share_link_discount = this.props.share_link_discount;
		titular.first_name = lead.first_name;
		titular.last_name = lead.last_name;
		titular.email = lead.email;
		titular.phone_one = lead.phone_one;

		if (Router?.router?.query) lead = { ...Router?.router?.query, ...lead };
		this.setState({ spinnerScreen: true, lead });
		const response = await this.props.actions.createNewLead(lead);
		if (response.success) this.props.actions.saveLeadEmail(lead.email);
		await this.props.actions.handleStateAffiliation('titular', titular);
		this.setState({ spinnerScreen: false, lead });
	};

	handleCheck = (value) => {
		let lead = this.state.lead;
		lead.agree_policy_privacy = value;
		this.setState({ lead });
	};

	handleClose = () => this.setState({ openAlert: false });

	saveState = (state, data) => this.setState(data);

	showDialog = () => {
		document.body.classList.add('modal-open');
		this.setState({ leadDialog: true });
	};

	closeDialog = () => {
		document.body.classList.remove('modal-open');
		this.setState({ leadDialog: false });
	};

	render() {
		const { quotation, quotation_affiliation, titular } = this.props;
		const {
			step,
			products,
			options,
			special_services,
			payment_methods,
			openAlert,
			messageAlert,
			loading,
			lead,
			dialog,
			countryCode,
			leadDialog
		} = this.state;
		return (
			<div className="w-full" id="cotizar">
				<style global jsx>
					{`
						.quote.telInputFull,
						.quote.telInputErrorFull {
							margin: 0;
							width: 100% !important;
						}
						.quote.telInputFull::before,
						.quote.telInputErrorFull::before {
							content: 'Número de teléfono válido *' !important;
						}
						@media only screen and (min-width: 600px) and (max-width: 959px) {
							.quote.telInputFull,
							.quote.telInputErrorFull {
								width: 100% !important;
							}
						}
					`}
				</style>
				{loading && <SpinnerV />}
				<p
					className={`text-base font-poppins text-left md:text-center font-bold mt-5 ${
						step === 1 ? 'text-gray-750 md:text-purple-500' : 'text-pink-500'
					}`}
				>
					{step === 1 && 'Cotiza en 1 minuto'}
					{step === 2 && '¡Obtén tu cotización online!'}
				</p>
				<h2 className="text-3xl md:text-4xl text-left md:text-center font-semibold font-poppins text-purple-500 mt-2 mb-6">
					{step === 1 && '¿En qué provincia reside tu familiar?'}
					{step === 2 && '¡Gracias por cotizar! Ya falta poco'}
				</h2>
				{step === 1 && (
					<div className="flex flex-wrap">
						<SelectSimpleOutlined
							name="city"
							value={quotation.city}
							label="Provincia donde viven tus familiares"
							placeholder="Selecciona provincia donde viven"
							listItems={CityList}
							addClass={`wInputThree ${
								quotation.city === 'default' && 'default'
							}`}
							onChange={(e) => this.handleInput(e)}
							required={true}
							otherOption={{
								value: 'OTRO',
								name: 'Mi provincia no aparece en la lista'
							}}
						/>
						<SelectSimpleOutlined
							name="amount"
							value={quotation.amount}
							label="Cantidad de familiares a cotizar"
							placeholder="Cantidad a cotizar"
							simpleList={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
							addClass={`wInputThree ${
								quotation.amount === 'default' && 'default'
							}`}
							onChange={(e) => this.handleInput(e)}
							required={true}
						/>

						{quotation?.amount !== 'default' && (
							<>
								{quotation?.parients.map((p, i) => (
									<DateInput
										key={i}
										clearable={true}
										required={true}
										name="birth_date"
										label="Fecha de nacimiento del familiar"
										placeholder="DD/MM/AAAA"
										maxDate={new Date()}
										value={p.birth_date}
										addClass="wInputThree"
										onChange={(date) => this.handleDate(date, i, 'birth_date')}
									/>
								))}
							</>
						)}
						<TextInput
							name="first_name"
							label="Nombre"
							placeholder="Escribe tu nombre"
							value={lead.first_name}
							helperText={lead.formErrors?.first_name}
							errors={lead.formErrors?.first_name}
							addClass="wInputThree"
							onChange={this.handleInputLead}
							required={true}
							disabled={this.props.token && titular.first_name ? true : false}
						/>
						<TextInput
							name="last_name"
							label="Apellido"
							placeholder="Escribe tu apellido"
							value={lead.last_name}
							helperText={lead.formErrors?.last_name}
							errors={lead.formErrors?.last_name}
							addClass="wInputThree"
							onChange={this.handleInputLead}
							required={true}
							disabled={this.props.token && titular.last_name ? true : false}
						/>
						<TextInput
							name="email"
							type="email"
							label="Correo electrónico"
							placeholder="Escribe tu correo electrónico"
							value={lead.email}
							helperText={lead.formErrors?.email}
							errors={lead.formErrors?.email}
							addClass="wInputThree"
							onChange={this.handleInputLead}
							required={true}
							disabled={this.props.token && titular.email ? true : false}
						/>
						<div className="wInputThree">
							{countryCode && (
								<ReactTelInput
									required={true}
									onChange={(telNumber) =>
										this.handleInputLead('phone_one', telNumber)
									}
									value={lead.phone_one}
									defaultCountry={countryCode}
									flagsImagePath={Flags}
									className={
										lead.phone_one && !lead.formErrors?.phone_one
											? 'quote telInputFull'
											: (!lead.phone_one && lead.formErrors?.phone_one) ||
											  lead.formErrors?.phone_one
											? 'quote telInputErrorFull'
											: 'quote telInputFull'
									}
									disabled={
										this.props.token && titular.phone_one ? true : false
									}
								/>
							)}
						</div>
						<div className={`${this.state.isMobile ? ' mt-5' : 'justify-center flex items-center accept-terms mt-4 w-full sm:justify-start md:mt-0'}`}>
							<FormControlLabel
								label={
									<span className={`${this.state.isMobile ? 'text-sm' : 'text-base flex items-center w-full accept-terms-text'}`}>
										Acepto las
										<a
											className={'ml-1 font-bold underline cursor-pointer text-purple-500'}
											onClick={() => this.setState({ dialog: true })}
										>
											Políticas de privacidad
										</a>
									</span>
								}
								control={
									<Checkbox
										className={
											lead.agree_policy_privacy === false
												? 'checkBoxWhite'
												: lead.agree_policy_privacy === true
												? 'checkBoxActive'
												: 'checkBox'
										}
										checked={lead.agree_policy_privacy}
										onClick={() =>
											this.handleCheck(
												lead.agree_policy_privacy === true ? false : true
											)
										}
										name="agree_policy_privacy"
										value={lead.agree_policy_privacy}
										inputProps={{ 'aria-label': 'text-base' }}
									/>
								}
							/>
						</div>
						{quotation?.city === 'OTRO' && (
							<div className="bg-purple-200 text-purple-500 text-sm leading-relaxed p-5 rounded mt-10 mx-auto">
								Si tu provincia no aparece es porque aún estamos trabajando para
								incluirla al alcance de asistensi, déjanos tus datos
								<b
									className="cursor-pointer ml-1"
									onClick={() => this.showDialog()}
								>
									aquí
								</b>
								.
							</div>
						)}
						<div className="flex justify-center w-full mt-12">
							<button
								className="primary-button  cursor-not-allowed"
								disabled={true}
								// {
								// 	!lead.first_name ||
								// 	!lead.last_name ||
								// 	!lead.email ||
								// 	!lead.phone_one ||
								// 	lead.agree_policy_privacy === false ||
								// 	Object.keys(lead.formErrors).length > 0 ||
								// 	quotation?.country === 'default' ||
								// 	quotation?.city === 'default' ||
								// 	quotation?.city === 'OTRO' ||
								// 	quotation?.amount === 'default' ||
								// 	quotation?.parients.find((p) => p.birth_date === null)
								// 		? true
								// 		: false
								// }
								onClick={() => this.handleChangeStep(2)}
							>
								¡Cotiza ya!
							</button>
						</div>
					</div>
				)}
				{step === 2 && (
					<div className="mt-12">
						<TablePlans
							products={products}
							options={options}
							special_services={special_services}
							onHandleChangeStep={this.handleChangeStep}
						/>
						{payment_methods?.length > 0 && (
							<QuotationBeneficiaries
								payment_methods={payment_methods}
								quotation={quotation_affiliation}
								quotationForSend={quotation}
								handleSave={this.props.actions.handleStateAffiliation}
								products={products}
								productsQuotation={this.props.actions.productsQuotation}
								saveQuotation={this.props.actions.saveQuotation}
								setMembershipOrigin={this.props.actions.setMembershipOrigin}
								fromLanding={this.props.fromLanding}
								share_link_discount={this.props.share_link_discount}
								token={this.props.token}
								user_type={this.props.user_type}
								isAlly={
									this.props.isAlly ||
									this.props.isAllyMaster ||
									this.props.isAllyMatrix
								}
								fromHome={this.props.fromHome}
							/>
						)}
					</div>
				)}
				<PrivacyPolicyDialog
					open={dialog}
					handleClose={() => this.setState({ dialog: false })}
				/>
				{leadDialog && (
					<NotFoundCity lead={lead} handleClose={this.closeDialog} />
				)}
				<SnackBar
					openAlert={openAlert}
					messageAlert={messageAlert}
					handleClose={this.handleClose}
				/>
			</div>
		);
	}
}

function mapStateToProps(state, props) {
	const quotation = state.customers.get('quotation');
	const quotation_affiliation = state.affiliation.get('quotation');
	const titular = state.affiliation.get('titular');
	const titular_email = state.affiliation.get('email');
	const token = state.affiliation.get('token');
	const user_type = state.affiliation.get('user_type');
	const share_link = state.ally.get('share_link');
	const share_link_discount = state.ally.get('share_link_discount');
	const isAllyMatrix =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 111;
	const isAllyMaster =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 110;
	const isAlly =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 100;

	return {
		quotation,
		quotation_affiliation,
		titular,
		share_link,
		share_link_discount,
		token,
		user_type,
		isAllyMatrix,
		isAllyMaster,
		isAlly,
		titular_email
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FastQuotation);
